<template>
    <div class="pg-box" :style="`width:${tot}px`">
        <!-- 外盒子 -->
        <div class="total" ref="total" :style="`width:${wVal}px`">
            <!-- <div class="val"></div> -->
        </div>

    </div>
</template>

<script>
export default {
    props: {
        // 总数
        tot: {
            type: Number
        },
        wVal: {
            type: Number
        }
    },
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.pg-box{
    background: #e8e8e8;
}
.total{
    /* width: 100%; */
    height: .2rem;
    background-image: -webkit-linear-gradient(45deg, #91FEBD, #194E72);
}
</style>