<template>
  <div class="virtual-card-box sm-qu-padding">
    <IsChildren />
    
    <CRow>
      
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          hover
          striped
          small
          fixed
          dark
          :pages="pages"
          :IssuingArr="IssuingArr"
          :IssuingData="issuingData"
          @IssuingMt="getData"
          @searchData="getData"
          caption="Combined All dark Table"
        />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import CTableWrapper from "../../components/Table.vue";
import IsChildren from "../../components/IsChildren.vue";
import { getCardList, getIssuingCardInfo } from "../../api/http";
// import TextScroll from "../../components/TextScroll.vue"
import {
  Loading
} from 'element-ui';
export default {
  data() {
    return {
      data: [],
      data1: [
        // card_id：卡ID
          // card_number：卡号
          // cvv：安全码
          // expiration：有效期
          // alias：持卡人姓名
          // status：状态
          // available_amount：余额
          // limit_amount：增值额
          // create_time：创建时间
          // tag：标签
        {
            card_number: "621226123123123123",
            alias: "Michael Kors",
            expiration: "12/14",
            cvv: '123',
            // Balance: 46.28,
            Tag: "-",
            Consumed: 12.99,
            status: "Active",
            available_amount:243,
            limit_amount:521,
            create_time: "2021/1/1 00:00",
            Edit: "",
            id: "18929"
        },
        {
          card_number:"27868362863283232322",
          cvv:2121,
          expiration:"21/21",
          alias:"持卡人姓名",
          // status:"Issuing",
          status:"Active",
          available_amount:243,
          limit_amount:521,
          create_time:"2022/5/31",
          tag:"test",
          id: "18928"
        },
        {
          card_number:"27868362863283232322",
          cvv:2121,
          expiration:"21/21",
          alias:"持卡人姓名",
          status:"Active",
          available_amount:24,
          limit_amount:120,
          create_time:"2022/5/31",
          tag:"test",
          id: "18927"
        },
      ],
      pages: null,
      idds: [],
      ids: "",
      ifIsu: false,
      IssuingArr: [],
      timer: null,
      ifDib: false,
      issuingData: [],
      issuTimer: null,
      issuLen: 0,
      // textArr: [
      //   {
      //     id: 1,
      //     val: "虚拟卡-虚拟卡"
      //   }
      // ]
    };
  },
  watch: {
    // issuLen(e) {

    // },
    issuingData: {
      handler(nv,ov) {
        if(nv.length === 0) {
          clearInterval(this.issuTimer)
          this.issuTimer = null
        }else {
        window.clearInterval(this.issuTimer)
        this.issuTimer = setInterval(() => {
        
        this.getIsingData()
        console.log("触发")
        // if(nv.length < ov.length) {

        // }
        
      },5000)
        }
      }
    },
    data: {
      handler(n,o) {
        let iss = n.filter((item) => item.status == 'Issuing')
    console.log('iss==>', iss)
    iss.forEach((item) => {
      if(item.status !== 'Issuing') {
        window.clearInterval(this.timer)
        // this.getData();
        this.$set(item,"ifDib", false)
      }
      this.$set(item,"ifDib", true)
      window.clearInterval(this.timer)
      this.timer = setInterval(() => {
        
        this.getData();
        console.log("触发")
        
      },5000)
      
    })
      },
         deep: true,
      immediate: true
      
    }
    // data1: {
    //   handler(newValue, oldValue) {
    //     console.log('newValue', newValue)
    //     console.log('oldValue', oldValue)
    //     let that = this
    //     newValue.forEach((item) => {
    //       if(item.status == "Issuing") {
    //         clearInterval(that.timer)
    //         that.timer = setInterval(() => {
    //           // that.$emit("IssuingMt")
    //           console.log("触发")
    //         }, 5000)
    //       }else {
    //         clearInterval(that.timer)
    //         console.log("停止")
    //       }
    //     })
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  methods: {
    // 请求通告数据
    // getAnnouncement(noticeType) {
    //   let obj = {
    //     uid: window.localStorage.getItem("uid"),
    //     type: "get_page_notice",
    //     // page: "fund_fund"
    //     page: noticeType
    //   }
    //   getPageNotice(obj).then((res) => {
    //     console.log("notice==>", res)
    //   })
    // },
    getIsingData() {
      let data = {
        type: "get_issuing_card",
        uid: localStorage.getItem("uid")
      }
      let formData3 = new FormData();
      for (let it in data) {
        formData3.append(it, data[it]);
      }
      this.$axios({
        method: "POST",
        url: "/request/user.php",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: formData3,
      }).then((res) => {

          // this.pages = Number(res.data.data.size);
          // this.data = res.data.data.data;
          
        // console.log("carlist res==>", res);
        if (res.data.code == 200) {
          console.log("carlist res==>", res);
          this.issuingData = res.data.data;
        } else if(res.data.code == 401) {
          this.$router.push("/pages/login")
        } 
        else {
          this.$message.error(res.data.message);
        }
      }).catch((e) => {
        console.log('ero==>', e)
      })
      // getIssuingCardInfo(data).then((res) => {
      //   console.log('resinsuuing==>', res)
      //   if (res.code == 200) {
      //     this.issuingData = res.data;
      //   } else {
      //     this.$message.error(res.message);
      //   }
      // })
    },
     // card_id：卡ID
          // card_number：卡号
          // cvv：安全码
          // expiration：有效期
          // alias：持卡人姓名
          // status：状态
          // available_amount：余额
          // limit_amount：增值额
          // create_time：创建时间
          // tag：标签
    getData(bin, name, card_name, tag, page) {
      var loading = Loading.service({
      fullscreen: true
    });
      if (bin === this.$t("VirtualCard.Bin[0]")) {
        bin = "";
      }
      let obj = {
        type: "get_card_list",
        uid: window.localStorage.getItem("uid"),
        bin: bin || "",
        name: name || "",
        card_number: card_name || "",
        tag: tag || "",
        page: page || 1,
        page_size: 20,
      };
      let formData2 = new FormData();
      for (let it in obj) {
        formData2.append(it, obj[it]);
      }
      this.$axios({
        method: "POST",
        url: "/request/user.php",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: formData2,
      }).then((res) => {

          // this.pages = Number(res.data.data.size);
          // this.data = res.data.data.data;
          
        // console.log("carlist res==>", res);
        if (res.data.code == 200) {
          console.log("carlist res==>", res);
          loading.close()
          this.pages = Number(res.data.data.size);
          this.data = res.data.data.data;
        } else if(res.data.code == 401) {
          loading.close()
          this.$router.push("/pages/login")
        } 
        else {
          loading.close()
          this.$message.error(res.data.message);
        }
      }).catch((e) => {
        console.log('ero==>', e)
      })
      // getCardList(data).then((res) => {
      //   console.log("carlist res==>", res);
      //   if (res.code == 200) {
      //     this.pages = Number(res.data.size);
      //     this.data = res.data.data;
      //   } else {
      //     this.$message.error(res.message);
      //   }
      // });
    },
    // 是Issuing卡每5s一次请求接口
    reqIssuing(arr) {
      let data = {
        type: "get_card_by_ids",
        uid: window.localStorage.getItem("uid"),
        ids: JSON.stringify(arr), // [184954,184955]
      };
      let formData = new FormData();
      for (let k in data) {
        formData.append(k, data[k]);
      }
      this.$axios({
        method: "POST",
        url: "/request/user.php",
        data: formData,
      }).then((code) => {
        if (code.data.code == 200) {
          console.log('ids data==>', code.data.data)
          this.IssuingArr = code.data.data
          this.issuLen = code.data.data.length
        } else {
        }
      });
    },
    // shuffleArray (array) {
    //   for (let i = array.length - 1; i > 0; i--) {
    //     let j = Math.floor(Math.random() * (i + 1))
    //     let temp = array[i]
    //     array[i] = array[j]
    //     array[j] = temp
    //   }
    //   return array
    // },

    // getShuffledUsersData () {
    //   return this.shuffleArray(usersData.slice(0))
    // }
  },
  created() {
    this.getData();
    this.getIsingData()
    
  },
  beforeDestroy() {
    window.clearInterval(this.timer)
    window.clearInterval(this.issuTimer)
    this.issuTimer = null
    this.timer = null
  },
  components: { CTableWrapper, IsChildren },
};
</script>
<style scoped>
.virtual-card-box {
  padding-left: 2.6rem;
  /* overflow: hidden; */
}

@media screen and (max-width: 520px) {
  .sm-qu-padding {
    padding: 0;
  }
}
</style>