<template>
    <div class="content">
        <div class="" style="display: flex;justify-content: space-between;align-items: center;">
            <div class="grayLine">
                <div class="redLine" :style="{width:decimalToPercent()}">
                    
                </div>
            </div>
            <!-- <div class="" style="margin-right: 10px;">
                {{decimalToPercent()}}
            </div> -->
        </div>
        
        <!-- <div class="bot">
            <div class="botTil">
                已到人数：{{a}}
            </div>
            <div class="botTil">
                总人数：{{total}}
            </div>
        </div> -->
    </div>
</template>

<script>
    export default {
        props: {
            a: {
                type: Number || String
            },
            total: {
                type: Number || String
            }
        },
        data() {
            return {
                // a: 10,
                // total: 30,
            }
        },
        methods: {
            //小数转化百分比
            decimalToPercent() {
                let data = ((this.a/this.total)*100).toFixed(2)+'%'
                return data
            },
        }
    }
</script>

<style scoped lang="scss">
    .content {
        .bot {
            display: flex;
            justify-content: space-between;
            // margin: 0 30px;
            .botTil {
                height: 20px;
                font-size: 15px;
                font-family: Arial;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
            }
        }
        .grayLine {
            // margin: 30px;
            height: 3px;
            width: 100%;
            background: #9d9696;
            border-radius: 1.5px;
            .redLine {
                // background: #FB4243;
                height: 100%;
                background-image: -webkit-linear-gradient(230deg, #91FEBD, #194E72);
                -webkit-text-fill-color: transparent;
            }
        }
    }
    
</style>