<!--
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2023-02-14 10:36:57
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-02 19:59:24
-->
<template>
    <div>
        <!-- <transition-group name="animate__animated" appear enter-active-class="animate__animated animate__fadeInDown" leave-active-class="animate__fadeOutRight"> -->
    <div class="text-container" v-if="isVis" key="vis">
        <!-- <img src="../assets/lb.png" alt="" srcset=""> -->
        <!-- <transition class="" name="slide" mode="out-in">
            <p style="margin-left:10px" class="text" :key="text.id">{{text.val.val}}</p>
            
        </transition> -->
        <!-- <div style="text-align:right">
            <i class="el-icon-close closeys" @click="closex"></i>
        </div> -->
        <div class="dispul" @mouseenter="stopPlay" @mouseleave="cxStartPlay">
            <ul :class="play == true ? 'anim' : 'uls'">
                <li class="lis" v-for="(item,index) in dataList" :key="index">
                    {{ item.content }}
                </li>
            </ul>
                <el-popover
                ref="popover2"
                placement="bottom"
                width="500"
                trigger="click"
                v-model="ispv"
                >
                <div class="more-box" @mouseenter="stopPlay" @mouseleave="cxStartPlay">
                    <!-- <p class="pel" v-for="(item,index) in dataList" :key="index">· {{ item.content }}</p> -->
                    <p class="pel">· {{ dataList[0].content }}</p>
                    <!-- <p class="pel">· dshdshdjhsdkskfs</p> -->
                    <!-- <p class="pel">· 的撒还打算看到发库哈斯哈萨克就大声的看啥都</p> -->
                </div>
            </el-popover>
            <!-- <el-tooltip :manual="true" :open-delay="5000" :hide-after="5000" v-model="isTip" effect="dark" :content="$t('Dashboard.ClickMore')" placement="top-start"> -->
                <el-button v-popover:popover2 class="xqbtn" size="mini" style="">···</el-button>
                <!-- <i v-popover:popover2 class="xqbtn">···</i> -->
            <!-- </el-tooltip> -->
            <i class="el-icon-close closeys" @click="closex"></i>
        </div>
    </div>
        <!-- </transition-group> -->
    </div>
</template>

<script>
// import { getPageNotice } from "../api/http"

export default {
  name: 'TextScroll',
  props: {
    dataList: {
      type: Array,
      default() {
        return [];
      },
    },
    isVis: {
        type: Boolean,
        default() {
            return false
        }
    }
  },
  data() {
    return {
      count: 0, // 当前索引
      intervalId: null, // 定时器ID
      playTime: 5000, // 定时器执行间隔
      play: false,
      timer: null,
      isTip: false,
      ispv: false
    //   moreArr: []
    };
  },
  computed: {
    text() {
      return {
        id: this.count,
        val: this.dataList[this.count],
      };
    
    },
    // moreArr() {
    //     return this.dataList
    // },
    noticeType() {
        if(this.$route.path == "/virtual-card/card-create") {
            return "card_create"
        }else if(this.$route.path == "/dashboard") {
            return "dashboard"
        }else if(this.$route.path == "/funds/funds") {
            return "fund_fund"
        }
        else if(this.$route.path == "/funds/accounts") {
            return "fund_account"
        }
        else if(this.$route.path == "/virtual-card/card-list") {
            return "card_card"
        }
        else if(this.$route.path == "/card-detail/consumer-details") {
            return "transaction_card"
        }
        else if(this.$route.path == "/card-detail/account-flow") {
            return "transaction_account"
        }
        else if(this.$route.path == "/reward-center/account") {
            return "agent_account"
        }
        else if(this.$route.path == "/reward-center/rewards") {
            return "agent_details"
        }
        else if(this.$route.path == "/reward-center/hierarchy") {
            return "agent_statistic"
        }else {
            return ""
        }
    }
  },
  created() {
      this.isTip = true
    //   this.moreArr = this.dataList
    // this.intervalId = setInterval(() => { // 定义定时器
    //   this.getText();
    // }, this.playTime);
    // this.getAnnouncement()
//     首页：dashboard
// 资金-资金：fund_fund
// 资金-账户：fund_account
// 虚拟卡-虚拟卡：card_card
// 虚拟卡-创建卡：card_create
// 明细-虚拟卡：transaction_card
// 明细-账户：transaction_account
// 代理中心-代理账户：agent_account
// 代理中心-账户明细：agent_details
// 代理中心-统计：agent_statistic
    this.$emit('handlerNotice', this.noticeType)
    this.timer = setInterval(this.startPlay, this.playTime)
    setTimeout(() => {this.isTip = false}, 5000)
    // this.startPlay()
  },
  mounted() {
      
  },
  watch: {
      
  },
//   activated() {
//       setInterval(this.startPlay, this.playTime)
//   },
  methods: {
    //   鼠标移入停止滚动
      stopPlay() {
        //   this.play = false
        //   this.intervalId = null
          clearInterval(this.timer)
          this.timer = null
        //   clearTimeout(this.intervalId)
          console.log('鼠标移入')
      },
    //   鼠标移出开始滚动
    cxStartPlay() {
        console.log('鼠标移出')
        this.ispv = false
        this.timer = setInterval(this.startPlay, this.playTime)
        // setInterval(this.startPlay, this.playTime)
    },
    // 请求通告数据
    // getAnnouncement() {
    //   let obj = {
    //     uid: window.localStorage.getItem("uid"),
    //     type: "get_page_notice",
    //     page: "card_create"
    //   }
    //   getPageNotice(obj).then((res) => {
    //     console.log("notice==>", res)
    //   })
    // },
    startPlay() {
        let that = this
        if(that.dataList.length == 1 || that.dataList.length == 0) {
            this.play = false
            return
        }
        that.play = true
        this.intervalId = setTimeout(() => {
            that.dataList.push(that.dataList[0])
            that.dataList.shift()
            that.play = false
        }, 1500)
    },
    closex() {
        this.isVis = false
    },
    getText() {
      const len = this.dataList.length; // 获取数组长度
      if (len === 0) {
          this.isVis = false
        return; // 当数组为空时，直接返回
      }
      if (this.count === len - 1) { // 当前为最后一个时
        this.count = 0; // 从第一个开始
      } else {
        this.count++; // 自增
      }
    },
  },
  destroyed() {
    clearInterval(this.timer); // 清除定时器
    this.timer = null
    this.intervalId = null
    // this.isTop = false
  },
};
</script>

<style scoped>
.pel{
    margin: 0;
    padding-bottom: 10px;
}
.pel:last-child{
    padding-bottom: 0;
}
.xqbtn{
    background:#409EFF;
    color:#fff;
    border:0;
    font-size:22px;

}
.closeys{
    font-size: 20px;
    cursor: pointer;
    margin-right: 5px;
    /* margin-top: 5px; */
    height: 35px;
    line-height: 35px;
}
.dispul{
    height: 35px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.uls{
    width: 100%;
    padding: 0;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 2;

}
.anim{
    width: 100%;
    transition: all .5s;
    margin-top: -35px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0;
}
.lis{
    width: 100%;
    list-style: none;
    /* display: flex; */
    /* align-items: center; */
    /* padding-bottom: 2px; */
    /* margin-bottom: 10px; */
    font-weight: 500;
    height: 35px;
    line-height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* border-bottom: 1px solid #dcdfe630; */
}
.lis:last-child{
    border: 0;
}
.text-container{
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    font-size: 14px;
    color: #fff;
    margin-right: 20px;
    /* height: 30px; */
    /* line-height: 30px; */
    background: #409eff;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 20px;
}

/* .text {
    text-align: right;
    margin: auto 0;
} */

.text{
    margin: 0;
    font-weight: bold;
}

.slide-enter-active, .slide-leave-active {
    transition: all 1s;
}

.slide-enter{
    transform: translateY(25px);
}

.slide-leave-to {
    transform: translateY(-25px);
}
</style>