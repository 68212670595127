<template>
    <div class="box">
        <div class="name" >{{name}}</div>
        <div class="value" >
          {{value}}
          <span></span>
        </div>
        <div class="total" ref="total"></div>
        
    </div>
</template>

<script>
  export default {
    name: "dataItem",
    props:{
      name:{
        type:String,
        default:()=>('')
      },
      total:{
        type:Number,
        default:()=>(24)
      },
      value:{
        type:Number,
        default:()=>(18)
      },
    },
    data(){
      return{
      };
    },

    mounted(){
      let that=this
      this.initStatus(16);    
    },
    
    updated() {
      // this.initStatus(16);
    },

    methods:{
        initStatus(cubeCount){
          let that=this;
          let totalDomWidth=this.$refs.total.offsetWidth;
          // console.log('totalDomWidth', totalDomWidth)
          let ratio=(this.value/this.total);
          let cubeWidth=Math.floor((totalDomWidth/cubeCount)-1);
          // console.log('cubeWidth', cubeWidth)
          // console.log('cubeCount', cubeCount)
   
          let cubeDomArr;
               
          for(let i=0;i<cubeCount;i++){
            let cubeDom=document.createElement('span');
            // console.log('cubDaom==>', cubeDom)      
            cubeDom.style.background='#0F3D61'    
            cubeDom.style.width=cubeWidth+'px'         
            this.$refs.total.appendChild(cubeDom)
          }
                
          let nowCubeCount=Math.floor(cubeCount*ratio);              
          // let nowCubeCount=Math.floor((cubeCount*ratio)-1)
          cubeDomArr=this.$refs.total.children;
          // console.log('cubeDomArr==>', cubeDomArr)
                
          let startColor=[16,139,247]; 
          let endColor=[15,218,250];
          let perDiffColor=[];
          
          for(let i=0;i<endColor.length;i++){
            perDiffColor.push( Number(((endColor[i]-startColor[i])/nowCubeCount).toFixed(3)))
          }
        
          for(let i=0;i<nowCubeCount;i++){
            cubeDomArr[i].style.background= 
            `RGB(
              ${startColor[0]+i*perDiffColor[0]},
              ${startColor[1]+i*perDiffColor[1]},
              ${startColor[2]+i*perDiffColor[2]})
            `
          }
          
          
        },
    },


  }
</script>

<style lang="scss" scoped>
    // @import "./packages/common/style/mixin.scss";
    .box{
        width: px2vw(540);
        height: px2vh(58);
        position: relative;          
    }
    .box .name{
        position: absolute;
        font-size: px2font(23);
        color: #fff;
        left: 0;
        top: 0;
    }

    .box .total{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        // height: px2vh(15);
        height: .2rem;
       // border-radius: px2vh(7);
       // background-color:#0F3F63;
     //  border: 1px solid red;
       display: flex;
       justify-content: space-between;
       transition: width 0.6s ease;
       span{
           transition: width 0.6s ease;
       }
    }
    

    .box .value{
        position: absolute;
        color: #fff;
        font-size: px2font(30);
        right: 0;
        top: 0;
    }
    
    .box .value span{
      font-size: px2font(23);
    }

</style>
